import React from 'react';
import {graphql } from 'gatsby'
import CommonTemplate from './common-template'
import FormTemplate from './form-template';
import SEO from "../Components/Seo/seo"
const DefaulTemplate = (props) => {
  const GQLPage = props.data.glstrapi?.article;
  const GQLAreaGuides = props.data.glstrapi?.areaguides;
  const GQLOffices = props.data.glstrapi?.ourOffices;
  const GQLTeams = props.data.glstrapi?.teams;
  const GQLModules = props.data.glstrapi?.article?.Modules;
  const GQLArticlesTeams = props.data.glstrapi?.teams;
  const GQLConfig = props.data.glstrapi?.globalConfig;
  if(GQLPage.Select_Template === 'Form_Page') {
    return (
      <>
      <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Title} description={GQLPage.Meta_Description} />
      <FormTemplate  GQLPage={GQLPage} GQLModules={GQLModules} GQLConfig={GQLConfig}/>
      </>
    )
  } 
  else {
    return  (
      <>
      <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Title} description={GQLPage.Meta_Description} />
      <CommonTemplate GQLTeams={GQLTeams} GQLPage={GQLPage} GQLModules={GQLModules} GQLAreaGuides={GQLAreaGuides} GQLOffices={GQLOffices} GQLArticlesTeams={GQLArticlesTeams} GQLConfig={GQLConfig} />
      </>
    )
  }
};
export default DefaulTemplate;

export const pageQuery = graphql`
query DefaultQuery ($articleId: ID!) {
  glstrapi {
    globalConfig {
      Sidebar_Title
      Sidebar_Title_Description
      Toogle_Description
      Info_Toogle
      Email_Postcode_Mapping
    }
    teams  (sort: "Sort:ASC"){
      id
      URL
      Name
      Designation
      Staff_Image {
        url
        alternativeText
      }
      Select_Office {
        Name
        URL
      }
      Select_Department {
        Name
      }
      imagetransforms
    }
    ourOffices {
      id
      Name
      URL
      Meta_Title
      Meta_Description
      Office_Address
      Sales_Phone_Number
      Lettings_Phone_Number
      Elfsight_Code
      Latitude
      Longitude
      Tile_Image {
        url
        alternativeText
      }
      imagetransforms
    }
    areaguides(sort: "Name:ASC"){
      Name
      Meta_Title
      Meta_Description
      Short_Intro
      Tile_Image {
        url
        alternativeText
      }
      Short_Intro
      URL
      id
      Youtube_Video_URL
      imagetransforms
    }
    article(id: $articleId, publicationState: LIVE) {
      Title
      Select_Template
      Meta_Title
      Meta_Description
      Page_Class
      Body_Background_Color
      Article_With_Sidebar
      BrokerCode_Data {
        id
        BrokerCode
        showBrokerCode
        BrokerCodeImg {
          alternativeText
          url
        }
      }
      Select_Popular_Search
      Show_Holidays_Banner
      Select_Staff {
        id
        URL
        Staff_Image {
          url
          alternativeText
        }
        imagetransforms
        Name
        Designation
        Phone_Number
      }
      teams {
        id
        Name
        URL
        Designation
        Phone_Number
        imagetransforms
        Staff_Image {
          url
          alternativeText
        }
        Select_Office {
          Name
          URL
        }
      }
      Modules {
        ... on GLSTRAPI_ComponentComponentsBannerSlider {
          id
          __typename
          Banner_Slides {
            Slider_Content
            Slider_Title
            Slider_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 585
                    quality: 100
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
            banner_video_url,
            banner_link
          }
        }
        ... on GLSTRAPI_ComponentComponentsShortIntro {
          id
          __typename
          Short_Intro_Title
          Short_Intro_Content
          Select_Member {
            id
            URL
            Staff_Image {
              url
              alternativeText
            }
            imagetransforms
            Name
            Designation
            Phone_Number
          }
          CTA {
            CTA_Custom_Link
            CTA_Label
            CTA_Link {
              Primary_URL
            }
          }
          Section_Id
          App_Links {
            App_Store_Link
            Playstore_Link
          }
        }
        ... on GLSTRAPI_ComponentComponentsIntro {
          id
          __typename
          Intro_Title
          Intro_Content
          Intro_Image_Banner {
            alternativeText
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 1600
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
          Intro_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 615
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
          Show_Reviews
          Show_Search_Form
          Intro_With_Filters
          Intro_CTA {
            CTA_Label
            CTA_Link {
              Primary_URL
            }
            CTA_Custom_Link
          }
        }
        ... on GLSTRAPI_ComponentComponentsBlogIntro {
          id
          __typename
          Intro_Title
          Intro_Content
        }
        ... on GLSTRAPI_ComponentComponentsImageGrids {
          id
          __typename
          Section_Title
          Section_Introtext
          Grid_List {
            Grid_Title
            Grid_Content
            Grid_Custom_Link
            Link_New_Tab
            Grid_Link_Label
            Grid_Link {
              Primary_URL
            }
            Grid_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 654
                    quality: 100
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsModules {
          id
          __typename
          Select_Module
        }
        ... on GLSTRAPI_ComponentComponentsStaticPage {
          id
          __typename
          Static_Intro_Title
          Static_Intro_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 845
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
          Static_Intro_Highlight_Text
          Static_Intro_Content
        }
        ... on GLSTRAPI_ComponentComponentsTwoColumnContent {
          __typename
          id
          Guideline_Image{
            url
            alternativeText 
          }
          Guideline_Content
          Left_Column_Content
          Right_Column_Content
        }
        ... on GLSTRAPI_ComponentComponentsAvailableOn {          
          id
          __typename
          Playstore_Link
          App_Store_Link
          Display_As_Fullwidth_Strip
          Available_On_Grid_Images {
            Avilable_Grid_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: WEBP
                    width: 426
                    height: 320
                    quality: 100
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsStats {
          id
          __typename
          Stats_List {
            Caption
            Value
            Value_Type
          }
        }
        ... on GLSTRAPI_ComponentComponentsAccordionBlock {
          __typename
          id
          Accordion_List {
            id
            Accordion_Title
            Accordion_Content
          }
          Sub_Accordion {
            id
            Sub_Accordion_Title
            Sub_Accordion_Team_Details {
              Team_Office_Name
              Select_Office_Members {
                Staff_Image {
                  id
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP
                        width: 72
                        height: 72
                        quality: 100
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )    
                    }
                  }
                }
                id
                Name
                Designation
              }
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsBalgoresBoostTop {
          id
          __typename
          Balogres_Boost_Top_Left_Content
          Balogres_Boost_Top_Right_Video_URL
          Balogres_Boost_Top_Right_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 540
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
          Balgores_Boost_Counter {
            Value
            Value_Content
            Value_Type
          }
        }
        ... on GLSTRAPI_ComponentComponentsBalgoresBoostBottom {
          id
          __typename
          Balogres_Boost_Bottom_Left_Content
          Balogres_Boost_Bottom_Link_Label
          Balogres_Boost_Bottom_Right_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 345
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
          Balogres_Boost_Bottom_Link {
            Primary_URL
          }
        }
        ... on GLSTRAPI_ComponentComponentsBoxTileBlock {
          id
          __typename
          Display_Type
          Block_Title
          Box_Item_List {
            Box_Item_Title
            Box_Item_Link_Label
            Box_Item_Caption
            Box_Item_Custom_Link
            Box_Item_Link {
              Primary_URL
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsFinancialServicesBottomSection {
          id
          __typename
          Financial_Services_Bottom_Right_Content
          Financial_Services_Bottom_Left_Content
          Financial_Services_Bottom_Grey_Block_Content
          Financial_Services_Bottom_Right_Image {
            alternativeText
            url
          }
        }
        ... on GLSTRAPI_ComponentComponentsForms {
          id
          __typename
          Select_Forms
        }
        ... on GLSTRAPI_ComponentComponentsAuctionsPage {
          id
          __typename
          Auctions_Top_Left_Content
          Next_Auction_Date
          View_Lots_Link
          Online_Auction_Link
          Upcoming_Auctions {
            Auction_Date
            Auction_Catalog_Link
          }
        }
        ... on GLSTRAPI_ComponentComponentsSuccessfulSale {
          id
          __typename
          Section_Heading
          Successful_Sale_List {
            Sale_Item_Title
            Sale_Item_Caption
          }
          Section_Bottom_CTA_Label1
          Section_Bottom_CTA_Label2
          Section_Bottom_CTA_Link1 {
            Primary_URL
          }
          Section_Bottom_CTA_Link2
        }
        ... on GLSTRAPI_ComponentComponentsValuationPage {
          id
          __typename
          Valuation_Page_Heading
          Valuation_Page_Description
          Valuation_Box_Snippet {
          	Valuation_Box_Icon_Class
            Valuation_Box_Title
            Valuation_Box_Description
            Valuation_Box_CTA_Label
            Valuation_Box_CTA_Link {
              Primary_URL
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsTileBlocks {
          id
          __typename
          Tile_Content
          Image_Position
          Tile_Youtube_Video_URL
          Tile_Custom_Link
          Tile_Link_Label
          Tile_Title
          Tile_Style
          Tile_Background
          Tile_Counter {
            Value
            Value_Content
            Value_Type
          }
          Tile_Accordion {
            id
            Accordion_Title
            Accordion_Content
          }
          Tile_Link {
            Primary_URL
          }
          Tile_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 540
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsBlurb {
            id
            __typename
            Title
            Introtext
            Image {
                url
                alternativeText
                url_sharp {
                    childImageSharp {
                        gatsbyImageData(
                            formats: AUTO
                            width: 500
                            quality: 100
                            layout: FIXED
                            transformOptions: {cropFocus: CENTER, fit: CONTAIN}
                        )  
                    }
                }
            }
            Blurb_Content_module {
                id
                Heading
                Content
                Icon {
                    url
                    alternativeText
                }
            }
        }
        ... on GLSTRAPI_ComponentComponentsIconBlock {
            id
            __typename
            Icon_list {
                id
                Icon_Name
                Icon {
                    id
                    url
                    alternativeText
                    url_sharp {
                        childImageSharp {
                          gatsbyImageData(formats: AUTO
                            width: 113
                            quality: 100)
                        }
                    }
                }
            }
        }
      }
      
    }
  }
}`
